import { useEffect, useRef } from 'react';
import anime from 'animejs';
import LogoS from '../../../assets/images/logo-s.png';
import './index.scss';

const Logo = () => {
  const bgRef = useRef();
  const outlineLogoRef = useRef();
  const solidLogoRef = useRef();

  useEffect(() => {
    const timeline = anime.timeline({
      easing: 'linear',
    });

    timeline
      .add({
        targets: bgRef.current,
        duration: 1000,
        opacity: 1,
      })
      .add({
        targets: outlineLogoRef.current,
        duration: 2000,
        strokeDashoffset: [anime.setDashoffset, 0],
        easing: 'easeInOutSine',
      })
      .add({
        targets: solidLogoRef.current,
        duration: 1000,
        opacity: 1,
      })
      .add({
        targets: outlineLogoRef.current,
        duration: 2000,
        strokeDashoffset: [0, anime.setDashoffset],
        easing: 'easeInOutSine',
      });

    return () => {
      timeline.pause();
    };
  }, []);

  return (
    <div className="logo-container" ref={bgRef}>
      <img
        className="solid-logo"
        ref={solidLogoRef}
        src={LogoS}
        alt="JavaScript Developer"
      />

      <svg
        width="559pt"
        height="897pt"
        version="1.0"
        viewBox="0 0 559 897"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          className="svg-container"
          transform="translate(0 457) scale(.1 -.1)"
          fill="none"
        >
          <path
            ref={outlineLogoRef}
            d="m2930 4560c-344-16-623-85-915-228-231-114-406-241-600-436-61-60-145-137-188-169-432-325-715-757-806-1230-109-564 21-1117 384-1641 250-360 780-877 1547-1511 451-373 600-505 803-708 215-216 275-293 350-448 55-114 75-188 82-298 6-96-6-173-39-257-21-53-27-59-73-76-136-51-374-73-521-46-401 71-763-963 796-34 71-60 142-60 170 0 25 117 190 175 252 342 397 776 753 1271 1046 122 72 227 135 233 141 8 7 11 25 9 53-2 29-10 48-27 68-30 34-75 49-110 36-12-4-61-35-108-68-361-240-640-440-838-585-116-92-180-133-324-210-189-100-276-147-323-175-70-41-96-71-146-161-17-30-40-60-51-67-11-6-29-24-41-40-11-16-44-49-73-74-58-48-163-117-228-145-57-24-176-31-223-12-71 25-129 74-187 154-108 137-137 288-113 585 9 119 7 140-15 231-29 112-78 238-108 277-23 31-52 82-52 92 0 2 11 23 25 47 38 60 40 100 13 250-24 136-30 171-45 255-21 131-20 200 5 355 30 206 28 426-5 668-17 117-31 199-41 233-21 74-88 122-147 110z"
            strokeWidth="200"
          />
        </g>
      </svg>
    </div>
  );
};

export default Logo;
